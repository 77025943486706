import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { FormattedHTMLMessage, Link, useIntl } from 'gatsby-plugin-intl';
import Section from '../Section';
import Container from '../Container';
import Button from '../Button';
import AppStore from '../../svgs/app-store.svg';
import GooglePlay from '../../svgs/home/google-play.svg';
import Bloomberg from '../../svgs/home/partner-logo/Bloomberg.svg';
import SCMP from '../../svgs/home/partner-logo/SCMP.svg';
import Tatler from '../../svgs/home/partner-logo/Tatler.svg';
import FT from '../../svgs/home/partner-logo/Financial-Times.svg';
import BI from '../../svgs/home/partner-logo/Business-Insider.svg';
import Nikkeiasia from '../../svgs/home/partner-logo/Nikkeiasia.svg';
import { useAppContext } from '../../context/AppContext';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { langMap } from '../../constants/dataMap';
import ReqDemoBtn from '../ReqDemo';

const HomeDownload = () => {
  const intl = useIntl();
  const lang = langMap[intl.locale];
  const data = useStaticQuery(graphql`
    query {
      homeDownload: file(relativePath: { eq: "home-download/product.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  // 【合作伙伴logo】的长与宽：[Web, IPAD, MOBILE]
  const logoW = [152, 100, 90];
  const logoH = [47, 40, 38.6];
  return (
    <>
      <div
        css={css`
          max-width: 100%;
          ${Tablet} {
            max-width: 768px;
            position: relative;
          }
          ${Desktop} {
            max-width: 1440px;
            // width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            margin-top: 134px;
            margin-bottom: 134px;
          }
        `}
      >
        <Container
          css={css`
            padding: 0 25px;
            ${Tablet} {
              padding: 0 30px;
            }
            ${Desktop} {
              padding: 0 20px;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              margin-top: 0px;
              // transform: translateY(-140px);
              // transform: translateY(120px);
              ${Tablet} {
                flex-direction: row;
                text-align: left;
                align-items: center;
                // transform: translateY(-80px);
              }
              ${Desktop} {
                margin-top: 130px;
                // transform: translateY(0);
              }
            `}
          >
            <div
              css={css`
                padding-top: 0px;
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-top: 50px;
                ${Tablet} {
                  width: 50%;
                  padding-top: 114px;
                }
                ${Desktop} {
                  padding-top: 0;
                  transform: translateY(-70px);
                }
              `}
            >
              <h1
                css={css`
                  color: #161616;
                  font-size: 24px;
                  line-height: 1.33;
                  margin-bottom: 20px;
                  font-weight: 500;
                  // width: 256px;
                  ${Tablet} {
                    font-size: 36px;
                    line-height: 1.33;
                    margin-bottom: 16px;
                    width: 506px;
                  }
                  ${Desktop} {
                    font-size: 36px;
                    line-height: 1;
                    margin-bottom: 36px;
                    width: 538px;
                  }
                `}
              >
                <FormattedHTMLMessage id='home.below-banner.title' />
              </h1>
              <h3
                css={css`
                  font-weight: normal;
                  font-size: 14px;
                  margin-bottom: 40.9px;
                  // width: 477px;
                  line-height: 1.71;
                  ${Tablet} {
                    font-size: 18px;
                    margin-bottom: 42px;
                    width: 477px;
                    line-height: 1.11;
                  }
                  ${Desktop} {
                    font-size: 24px;
                    margin-bottom: 36px;
                    width: 499px;
                    line-height: 1.38;
                  }
                `}
              >
                <FormattedHTMLMessage id='home.below-banner.description' />
              </h3>
              <div
                css={css`
                  display: flex;
                  z-index: 6;
                `}
              >
                <div
                  css={css`
                    width: 137px;
                    ${Tablet} {
                      width: 157px;
                    }
                    ${Desktop} {
                      width: 157px;
                    }
                  `}
                >
                  <ReqDemoBtn />
                </div>
                <a
                  // href="javascript:void(0)"
                  href='product/institutions/advisory'
                >
                  <div
                    css={css`
                      width: 137px;
                      margin-left: 15px;
                      ${Tablet} {
                        width: 157px;
                        margin-left: 30px;
                      }
                      ${Desktop} {
                        width: 157px;
                        margin-left: 30px;
                      }
                    `}
                  >
                    <Button
                      outline
                      text={intl.formatMessage({
                        id: 'home.below-banner.Discover',
                      })}
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Container>
        <div
          css={css`
            width: 100%;
            overflow: hidden;

            ${Tablet} {
              // width: 50%;
              // height: 594px;
              // width: 80%;
            }
            ${Desktop} {
              width: 647px;
              position: absolute;
              right: 0px;
              top: 0px;
            }
          `}
        >
          <Img
            fluid={data[`homeDownload`].childImageSharp.fluid}
            imgStyle={{ objectFit: 'fill' }}
            fadeIn={false}
            loading='eager'
            css={css`
              // position: absolute;
              width: 325px;
              margin: 70px auto 0 auto;
              // transform: translate(-30px, 0px);
              ${Tablet} {
                // width: 565px;
                width: 647px;
                height: 428px;
                transform: translate(0, 0);
                margin-top: 70px;
              }
              ${Desktop} {
                width: 647px;
                height: 428px;
                margin: 0;
              }
            `}
          />
        </div>
      </div>
      <div
        css={css`
          width: 100%;
          background-image: linear-gradient(
            to bottom,
            rgba(242, 242, 242, 0),
            #f2f2f2
          );
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 620px;
          margin-top: -360.6px;
          ${Tablet} {
            height: 350px;
            margin-top: 15px;
          }
          ${Desktop} {
            height: 497px;
            margin-top: -294px;
          }
        `}
      >
        <div
          css={css`
            margin-top: 428px;
            font-size: 12px;
            line-height: 1.71;
            font-weight: normal;
            ${Tablet} {
              margin-top: 56px;
              font-size: 18px;
              line-height: 1;
            }
            ${Desktop} {
              margin-top: 340px;
              font-size: 14px;
              line-height: 1.43;
            }
          `}
        >
          {intl.formatMessage({ id: 'home.below-banner.as-featured-in' })}
        </div>
        {/* 【合作伙伴LOGO】- WEB端 */}
        <Container
          css={css`
            display: none;
            ${Desktop} {
              // position: relative;
              // bottom: 0;
              margin-top: 18px;
              display: flex;
              justify-content: space-between;
            }
          `}
        >
          <Bloomberg width={152} height={47} />
          <SCMP width={152} height={47} />
          <Tatler width={152} height={47} />
          <FT width={152} height={47} />
          <BI width={152} height={47} />
          <Nikkeiasia width={152} height={47} />
        </Container>
        {/* 【合作伙伴LOGO】- IPAD端 */}
        <Container
          css={css`
            display: none;
            ${Tablet} {
              margin-top: 42.5px;
              display: block;
              // justify-content: space-between;
              padding: 0 92px;
            }
            ${Desktop} {
              display: none;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Bloomberg width={logoW[1]} height={logoH[1]} />
            <SCMP width={logoW[1]} height={logoH[1]} />
            <Tatler width={logoW[1]} height={logoH[1]} />
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-top: 50px;
            `}
          >
            <FT width={logoW[1]} height={logoH[1]} />
            <BI width={logoW[1]} height={logoH[1]} />
            <Nikkeiasia width={logoW[1]} height={logoH[1]} />
          </div>
        </Container>
        {/* 【合作伙伴LOGO】- MOBILE端 */}
        <Container
          css={css`
            margin-top: 23.2px;
            padding: 0 25px;
            display: block;
            ${Tablet} {
              margin-top: 42.5px;
              display: none;
              // justify-content: space-between;
              padding: 0 92px;
            }
            ${Desktop} {
              display: none;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <Bloomberg width={logoW[2]} height={logoH[2]} />
            <SCMP width={logoW[2]} height={logoH[2]} />
            <Tatler width={logoW[2]} height={logoH[2]} />
          </div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-top: 50px;
            `}
          >
            <FT width={logoW[2]} height={logoH[2]} />
            <BI width={logoW[2]} height={logoH[2]} />
            <Nikkeiasia width={logoW[2]} height={logoH[2]} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default HomeDownload;
